export const rangeTutorialSteps = [
    {
        title: ' Welcome',
        intro: 'Welcome to the tutorial mode for submitting a range order on StellarFX.',
    },
    {
        element: '#share_button',
        title: 'Share button',
        intro: 'This is the share button ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#settings_button',
        title: 'Range Settings',
        intro: 'Use this menu to adjust your slippage tolerance, as well as control your confirmation methods. ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_A_qty',
        title: 'Sell Quantity',
        intro: 'Enter your sell amount here ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_A_token_selector',
        title: 'Sell Token',
        intro: 'Select your sell token',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_B',
        title: 'Buy',
        intro: 'Repeat the same step for your buy token and quantity',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_advance_mode_toggle',
        title: 'Advanced Mode',
        intro: 'Toggle advanced mode ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_min_price',
        title: 'Min Price',
        intro: 'Enter your minimum price ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_max_price',
        title: 'Max Price',
        intro: 'Enter your maximum price ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
];

export const rangeTutorialStepsAdvanced = [
    {
        title: ' Welcome',
        intro: 'Welcome to the tutorial mode for submitting a range order on StellarFX.',
    },
    {
        element: '#share_button',
        title: 'Share button',
        intro: 'This is the share button ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#settings_button',
        title: 'Range Settings',
        intro: 'Use this menu to adjust your slippage tolerance, as well as control your confirmation methods. ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_A_qty',
        title: 'Sell Quantity',
        intro: 'Enter your sell amount here ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_A_token_selector',
        title: 'Sell Token',
        intro: 'Select your sell token',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_B',
        title: 'Buy',
        intro: 'Repeat the same step for your buy token and quantity',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_advance_mode_toggle',
        title: 'Advanced Mode',
        intro: 'Toggle advanced mode ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#range_width',
        title: 'Range Width',
        intro: 'Choose a range width by clicking on the preset values or by adjusting the slider. ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
];
